div[role=table]:not(.rdt_Table) div {
	margin-bottom: 20px;
}
.dropdown-item:hover {
	background-color: #feeceb !important;
	color: red !important;
}

.dropdown-menu svg {
	stroke: #6e6b7b !important;
}

.link-danger {
	color: #EE3424;
}

.sub-category-box {
	width: 100%;
	height: 20vh;
	background-color: #EE3424;
	position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
	/*background-repeat: no-repeat;
	background-position: center;
	background-size: cover;*/
}

.rdt_TableBody {
	min-height: 200px;
}

.profile-header .profile-img {
	border-radius: 50% !important;
}

#user-profile .profile-img .avatar-content {
	width: 125px !important;
	height: 125px !important;
	font-size: 2.5rem !important;
}

#user-profile .profile-img .avatar {
	position: absolute;
	top: 0;
	left: 0;
}

.profile-header-nav {
	min-height: 30px;
}

.form-control:hover, .form-control, select {
	border-color: #d4d4d4 !important;
}

.sub-category-box:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.3;
	background-image: url('../images/pages/stallning.jpeg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.sub-category-box h1 {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	color: #fff;
}

.profile-header img {
	max-height: 40vh;
}

.forgot-password-link, .default-link {
	color: #EE3424 !important;
}
.main-menu .navbar-header {
	height: 120px;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
	max-width: 100px;
}

.btn-primary-color, .btn-primary-color:hover, .btn-primary-color:active, .btn-primary-color:visited {
	border-color: #EE3424 !important;
    background-color: #EE3424 !important;
	color: #fff !important;
	//color: #EE3424 !important;
}

.btn-primary-color:hover {
	box-shadow: 0 4px 24px 0 rgba(#000, 0.1) !important;
}

.page-item.active .page-link {
	background-color: #EE3424 !important;
}

.main-menu.menu-light .navigation li ul .active, .vertical-layout .main-menu .navigation a.active {
    background: linear-gradient(118deg, #EE3424, #EE3424) !important;
	box-shadow: 0 0 10px 1px #f5a097;
}

#search-invoice {
	width: 40vw;
}

@media(max-width: 768px) {
	#dashboard-analytics {
		top: 30vh;
		position: absolute;
	}

	#search-invoice {
		width: 100%;
	}

	.sub-category-box {
		display: none;
	}

	.modal-content .btn-primary-color {
		margin-bottom: 20px !important;
	}
}

@media(max-width: 576px) {
	#user-profile .profile-img .avatar-content {
		width: 100px !important;
		height: 100px !important;
		font-size: 2rem !important;
	}
}